<template>
  <v-item-group
    style="min-height: 85vh; width: 100%; position: relative"
    v-if="permission !== null"
  >
    <div v-if="permission.read_perm === 1">
      <v-container
        style="
          background: rgba(255, 255, 255, 0.6);
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        "
      >
        <v-row no-gutters style="font-size: 18px; padding: 0; margin: 0">
          <v-col cols="12">
            <div id="clock" style="margin-bottom: 20px">
              <p class="date">{{ days }} {{ date }}</p>
              <p class="time">{{ time }}</p>
            </div>
            <div class="d-flex flex-column justify-center align-center">
              <p style="margin: 0; padding: 0; font-size: 14px">
                {{ publicIp }}
              </p>
              <a style="font-size: 12px" href="#" @click.prevent="networkDialog"
                >Lihat wifi terdaftar!</a
              >
            </div>
            <v-dialog v-model="openNetworkDialog">
              <v-card style="position: relative">
                <v-card-text class="d-flex justify-center align-center">
                  <v-col
                    md="12"
                    style="padding: 0; position: relative; top: 20px"
                  >
                    <v-data-table
                      mobile-breakpoint="0"
                      fixed-header
                      style="cursor: pointer"
                      :headers="headers"
                      :items="result"
                    >
                      <template v-slot:[`item.plant`]="{ item }">
                        <div
                          style="
                            font-size: 12px;
                            padding: 0;
                            display: flex;
                            flex-direction: row;
                          "
                        >
                          {{ item.plant.name }}
                        </div>
                      </template>
                    </v-data-table>
                  </v-col>
                </v-card-text>
                <v-card-actions
                  style="position: absolute; top: 0; right: 0; z-index: 99"
                >
                  <v-btn icon color="black" text @click="closeListNetwork">
                    <v-icon>mdi-close</v-icon>
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <div class="note">
              <div
                style="position: absolute; top: 0; left: 0; padding: 10px"
                class="d-flex justify-center align-start"
              >
                <v-icon
                  class="d-flex justify-center align-center"
                  style="margin: 3px 15px 0 10px"
                  color="primary lighten-2"
                  size="22px"
                  >mdi-information-outline</v-icon
                >

                <p style="margin: 0; padding: 0" class="subtitle-2">
                  Berdasarkan peraturan perusahaan, karyawan diharapkan hadir
                  pada jam <strong>08:00 </strong> dan pulang pada jam
                  <strong> 17:00 </strong>
                </p>
              </div>
            </div>
          </v-col>

          <v-col
            v-if="
              this.getUserProfile.level.find(({ id }) => id === '1') !==
                undefined ||
              this.getUserProfile.level.find(({ id }) => id === '37') !==
                undefined
            "
            cols="12"
            style="margin-top: 20px"
          >
            <v-card elevation="0" class="mx-auto" style="padding: 10px 15px">
              <v-toolbar-title
                class="overline"
                style="padding-left: 10px; overflow: none; text-align: center"
              >
                <p class="presensi-title">Presensi anda saat ini</p>
              </v-toolbar-title>
              <v-row no-gutters>
                <v-col cols="12" style="padding: 0">
                  <div v-if="attendanceStatus !== null" style="padding: 0 10px">
                    <div v-if="todayAttend !== null">
                      <v-row no-gutters style="text-align: center">
                        <v-col cols="12" sm="6">
                          <p
                            style="
                              margin: 0;
                              padding-top: 20px;
                              font-size: 14px;
                              font-weight: 500;
                            "
                          >
                            Jam Masuk
                          </p>
                          <div>
                            <v-btn
                              v-if="attendanceStatus === 'IN'"
                              type="button"
                              text
                              outlined
                              elevation="0"
                              color="primary"
                              class="white--text font-weight-bold ma-5"
                              style="font-size: 12px"
                              @click="openAttendanceForm('Presensi Masuk')"
                            >
                              Belum terekam
                            </v-btn>
                            <v-btn
                              v-if="
                                attendanceStatus === 'OUT' ||
                                attendanceStatus === 'NOT_ALLOWED'
                              "
                              type="button"
                              text
                              outlined
                              elevation="0"
                              color="primary"
                              class="white--text font-weight-bold ma-5"
                              style="font-size: 12px"
                              disabled
                            >
                              Sudah terekam
                            </v-btn>

                            <!-- <v-btn
                              v-if="
                                attendanceStatus === 'OUT' ||
                                  attendanceStatus === 'NOT_ALLOWED'
                              "
                              @click="
                                openDetailAttendance(
                                  todayAttend[todayAttend.length - 1]
                                )
                              "
                              type="button"
                              text
                              elevation="0"
                              color="primary"
                              class="white--text font-weight-bold ma-5 "
                              style="font-size:16px; cursor:default;"
                            >
                              {{
                                convertDate2(
                                  todayAttend[todayAttend.length - 1]
                                    .attendance_date
                                ) +
                                  ' ' +
                                  todayAttend[todayAttend.length - 1]
                                    .attendance_time
                              }}
                            </v-btn> -->
                          </div>
                        </v-col>
                        <v-col cols="12" sm="6">
                          <p
                            style="
                              margin: 0;
                              padding-top: 20px;
                              font-size: 14px;
                              font-weight: 500;
                            "
                          >
                            Jam Pulang
                          </p>
                          <div>
                            <v-btn
                              v-if="attendanceStatus === 'OUT'"
                              type="button"
                              text
                              outlined
                              elevation="0"
                              color="primary"
                              class="white--text font-weight-bold ma-5"
                              style="font-size: 12px"
                              @click="openAttendanceForm('Presensi Pulang')"
                            >
                              Belum terekam
                            </v-btn>

                            <v-btn
                              v-if="attendanceStatus === 'NOT_ALLOWED'"
                              type="button"
                              text
                              outlined
                              elevation="0"
                              color="primary"
                              class="white--text font-weight-bold ma-5"
                              style="font-size: 12px"
                              disabled
                            >
                              Sudah terekam
                            </v-btn>
                            <!-- <v-btn
                              v-if="attendanceStatus === 'NOT_ALLOWED'"
                              @click="openDetailAttendance(todayAttend[0])"
                              type="button"
                              text
                              color="primary"
                              class="white--text font-weight-bold ma-5 "
                              style="font-size:16px; cursor:default;"
                            >
                              {{
                                convertDate2(todayAttend[0].attendance_date) +
                                  ' ' +
                                  todayAttend[0].attendance_time
                              }}
                            </v-btn> -->
                          </div>
                        </v-col>
                      </v-row>
                    </div>
                  </div>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
        <v-dialog v-model="cameraDialog" fullscreen persistent>
          <v-card
            style="position: relative; width: 100%; height: 100%"
            class="d-flex justify-center align-center"
          >
            <vue-camera
              v-if="cameraDialog"
              style="position: relative; height: 90%; z-index: 2"
              @picture-taken="takePicture"
            />
            <v-card-actions
              style="position: absolute; top: 0; right: 0; z-index: 99"
            >
              <v-btn icon color="black" text @click="closeCamera()">
                <v-icon>mdi-close</v-icon>
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-container>
      <v-dialog v-model="dialog" width="500">
        <v-form
          :disabled="loading"
          ref="entryForm"
          @submit.prevent="submit"
          style="position: relative"
        >
          <v-card>
            <v-card-title class="text-h5 grey lighten-2">
              {{ attendanceTitle }}
            </v-card-title>

            <v-card-text class="d-flex justify-center align-center">
              <v-col md="12" style="padding: 0; position: relative; top: 20px">
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Area Presensi
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-autocomplete
                      v-model="form.attend_from"
                      :items="dropdown.area"
                      item-text="name"
                      item-value="id"
                      return-id
                      outlined
                      dense
                      clearable
                      style="margin: 0; height: 55px"
                      :rules="rules.attendFromRules"
                    ></v-autocomplete>
                  </v-col>
                </div>
                <div v-if="form.attend_from === 1" style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Lokasi Kantor
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-autocomplete
                      :items="getDropdownPlant"
                      item-text="name"
                      item-value="id"
                      return-object
                      outlined
                      dense
                      clearable
                      v-model="form.attend_from_company"
                      style="margin: 0; height: 55px"
                      :rules="rules.attendFromCompanyRules"
                    ></v-autocomplete>
                  </v-col>
                </div>
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      class="text-left"
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Deskripsi
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <v-textarea
                      v-model="form.attend_description"
                      dense
                      auto-grow
                      outlined
                      style="margin: 0"
                      :rules="rules.descriptionRules"
                    />
                  </v-col>
                </div>
                <div style="padding: 0 10px">
                  <v-col cols="12" style="padding: 0">
                    <p
                      style="
                        margin: 0;
                        font-size: 12px;
                        font-weight: bold;
                        color: rgba(0, 0, 0, 0.5);
                      "
                    >
                      Upload Foto
                    </p>
                  </v-col>
                  <v-col cols="12" style="padding: 0">
                    <div
                      style="
                        padding: 10px;
                        border: 2px dashed grey;
                        height: 70px;
                      "
                    >
                      <v-file-input
                        ref="photoProfile"
                        placeholder="-buka kamera-"
                        dense
                        append-icon="mdi-image"
                        prepend-icon=""
                        @change="uploadFoto"
                        :rules="rules.photoRules"
                        capture="camera"
                        accept="image/*"
                      ></v-file-input>
                      <!-- <v-text-field
                      @click="openCamera()"
                      dense
                      clearable
                      prepend-icon=""
                      append-icon="mdi-camera"
                      placeholder="-buka kamera-"
                      v-model="form.photo"
                      :rules="rules.photoRules"
                    /> -->
                    </div>
                  </v-col>
                </div>
              </v-col>
            </v-card-text>

            <v-divider></v-divider>

            <v-card-actions>
              <v-btn
                color="error"
                text
                outlined
                @click="close"
                :loading="loading"
              >
                Batal
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="primary"
                type="submit"
                text
                outlined
                :loading="loading"
              >
                Simpan
              </v-btn>
            </v-card-actions>
          </v-card>
        </v-form>
      </v-dialog>
      <v-dialog
        v-if="detailAttendance !== null"
        v-model="detailPresensi"
        width="500"
      >
        <v-card>
          <v-card-title class="text-h5 grey lighten-2">
            Detail Presensi
          </v-card-title>

          <v-card-text class="justify-center align-center">
            <v-col md="12" style="padding: 0; position: relative; top: 20px">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Area Presensi
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    :value="detailAttendance.attend_from"
                    dense
                    readonly
                    style="margin: 0"
                  />
                </v-col>
              </div>
            </v-col>
            <v-col
              v-if="detailAttendance.attend_from === 'Site'"
              md="12"
              style="padding: 0; position: relative; top: 20px"
            >
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Lokasi
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-text-field
                    :value="detailAttendance.attend_from_company_name"
                    dense
                    readonly
                    style="margin: 0"
                  />
                </v-col>
              </div>
            </v-col>
            <v-col md="12" style="padding: 0; position: relative; top: 20px">
              <div style="padding: 0 10px">
                <v-col cols="12" style="padding: 0">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Deskripsi
                  </p>
                </v-col>
                <v-col cols="12" style="padding: 0">
                  <v-textarea
                    :value="detailAttendance.attend_description"
                    dense
                    auto-grow
                    rows="1"
                    readonly
                    style="margin: 0"
                  />
                </v-col>
              </div>
            </v-col>
            <v-col md="12" style="padding: 0; position: relative; top: 20px">
              <v-row no-gutters style="padding: 0 10px">
                <v-col cols="3" style="padding: 0">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Foto :
                  </p>
                </v-col>
                <v-col cols="9" style="padding: 0">
                  <v-dialog v-model="openImageDialog" max-width="80%">
                    <template v-slot:activator="{ on, attrs }">
                      <a href="#" v-bind="attrs" v-on="on" @click.prevent
                        >Lihat Gambar</a
                      >
                    </template>
                    <div style="position: relative">
                      <v-img
                        v-if="openImageDialog"
                        :src="imgSrc"
                        @error="imgError"
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="primary"
                            ></v-progress-circular>
                          </v-row> </template
                      ></v-img>
                      <v-btn
                        color="black"
                        fab
                        x-small
                        @click="closeImg"
                        style="position: absolute; top: 10px; right: 10px"
                      >
                        <v-icon color="white" style="font-weight: bold"
                          >mdi-close</v-icon
                        >
                      </v-btn>
                    </div>
                  </v-dialog>
                </v-col>
              </v-row>
            </v-col>
            <v-col
              md="12"
              style="
                padding: 0;
                position: relative;
                top: 20px;
                margin-top: 10px;
              "
            >
              <v-row no-gutters style="padding: 0 10px">
                <v-col cols="3" style="padding: 0">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Lokasi :
                  </p>
                </v-col>
                <v-col cols="9" style="padding: 0">
                  <a
                    :href="`https://www.google.com/maps/search/?api=1&query=${detailAttendance.attend_from_loc.lt},${detailAttendance.attend_from_loc.lng}`"
                    target="_blank"
                    >Buka di google map</a
                  >
                </v-col>
              </v-row>
            </v-col>
            <v-col
              md="12"
              style="
                padding: 0;
                position: relative;
                top: 20px;
                margin-top: 10px;
              "
            >
              <v-row no-gutters style="padding: 0 10px">
                <v-col cols="3" style="padding: 0">
                  <p
                    class="text-left"
                    style="
                      margin: 0;
                      font-size: 12px;
                      font-weight: bold;
                      color: rgba(0, 0, 0, 0.5);
                    "
                  >
                    Perangkat :
                  </p>
                </v-col>
                <v-col cols="9" style="padding: 0">
                  <p
                    style="padding: 0; margin: 0; font-size: 14px"
                    class="subtitle-1"
                  >
                    {{ detailAttendance.device_type }}
                  </p>
                </v-col>
              </v-row>
            </v-col>
          </v-card-text>

          <v-divider></v-divider>

          <v-card-actions>
            <v-btn
              color="error"
              text
              outlined
              @click="detailPresensi = !detailPresensi"
            >
              Tutup
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </div>
  </v-item-group>
  <div v-else></div>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/2.2.4/jquery.min.js"></script>
<script>
import $ from 'jquery'
import axios from 'axios'
import buildType from '../../../services/buildType'
import { mapMutations, mapActions, mapGetters } from 'vuex'
import VueCamera from '../../home/components/Camera.vue'
// const NodeRSA = require('node-rsa')
export default {
  name: 'hrdashboard',
  components: {
    VueCamera
  },
  data: () => ({
    screenWidth: 0,
    screenHeight: 0,
    cameraDialog: false,
    dialog: false,
    detailPresensi: false,
    openImageDialog: false,
    openNetworkDialog: false,
    loading: false,
    // hrsApi: buildType.apiURL('hrsV2'),
    attendanceApi: buildType.apiURL('attendanceTrial'),
    upload: buildType.apiURL('upload2'),
    eSanQua: buildType.apiURL('esanqua'),
    menu_key: 45,
    application: {
      id: 21,
      name: 'HR System'
    },
    permission: null,
    attendanceTitle: '',
    date: '',
    time: '',
    days: '',
    week: ['MINGGU', 'SENIN', 'SELASA', 'RABU', 'KAMIS', 'JUMAT', 'SABTU'],
    publicIp: '...',
    // wifiName: '...',
    todayAttend: null,
    detailAttendance: null,
    form: {
      device_code: null,
      device_type: 3,
      attend_from: null,
      attend_from_company: null,
      attend_loc_lt: null,
      attend_loc_lng: null,
      attend_description: null,
      attend_from_ip: null,
      attend_from_ssid: null,
      hashed_data: null,
      photo: null,
      date: null
    },
    isLocationSupported: false,
    rules: {
      attendFromRules: [],
      attendFromCompanyRules: [],
      descriptionRules: [],
      photoRules: []
    },
    dropdown: {
      area: [
        { id: 1, name: 'kantor' },
        { id: 2, name: 'Lokasi Lain' },
        { id: 3, name: 'Rumah' }
      ]
    },
    imgSrc: '',

    headers: [
      {
        text: 'Perusahaan',
        value: 'plant',
        align: 'left',
        sortable: false
      },
      {
        text: 'Nama Wifi',
        value: 'wifi_ssid',
        align: 'left',
        sortable: false
      },
      {
        text: 'IP',
        value: 'ip',
        align: 'left',
        sortable: false
      }
    ],
    result: [],
    attendanceStatus: null
  }),
  async mounted() {
    this.setArrTabs()
    this.screenWidth = screen.width
    this.screenHeight = screen.height

    // Check Permission
    if (
      this.getUserProfile.level.find(
        ({ application }) => application.id === 1
      ) !== undefined
    ) {
      var level_id = this.getUserProfile.level.find(
        ({ application }) => application.id === 1
      ).id
      this.checkMenuAccess([this.menu_key, level_id])
    } else if (
      this.getUserProfile.level.find(
        ({ application }) => application.id === this.application.id
      ) !== undefined
    ) {
      var level_id = this.getUserProfile.level.find(
        ({ application }) => application.id === this.application.id
      ).id
      this.checkMenuAccess([this.menu_key, level_id])
    } else {
      this.resetPermission(null)
    }
  },
  created() {
    setInterval(this.updateTime, 1000)
    this.updateTime()
  },
  computed: {
    ...mapGetters(['getUserProfile', 'getDropdownPlant'])
  },
  methods: {
    ...mapActions(['menu_access', 'dropdownPlant']),
    ...mapMutations(['setTabs']),
    showMsgDialog(pModalType, pStatusMsg, pBtnCancel) {
      return new Promise((resolve) => {
        this.$swal
          .fire({
            position: 'center',
            icon: pModalType,
            html: '<strong style="font-size:18px;">' + pStatusMsg + '</strong>',
            showConfirmButton: true,
            showCancelButton: pBtnCancel,
            cancelButtonText: 'No'
          })
          .then((r) => {
            resolve(r)
          })
      })
    },
    async checkMenuAccess(payload) {
      await this.menu_access(payload)
        .then((result) => {
          this.resetPermission(result)
        })
        .catch((error) => {
          if (error) {
            this.resetPermission(null)
          }
        })
    },
    resetPermission(val) {
      if (val !== null) {
        if (val.data !== null) {
          this.permission = val.data
          if (val.data.read_perm === 1) {
            this.getPublicIp()
            this.getLocation()
            if (
              this.getUserProfile.level.find(({ id }) => id === '1') !==
                undefined ||
              this.getUserProfile.level.find(({ id }) => id === '37') !==
                undefined
            ) {
              this.getTodayAttendance()
              this.getAttendanceStatus()
            }
            this.getNetworkPlant()
          }
          return
        }
      }
      this.permission = {
        create_perm: 0,
        delete_perm: 0,
        read_perm: 0,
        update_perm: 0
      }
      return
    },
    imgError() {
      // this.isImgError = true
      this.imgSrc =
        'https://e-sanqua.sanquawater.co.id/image/ecatalogue/products/photo_1/ProductPhoto-jahsors8yollrrbjwsyx7.png'
    },
    setArrTabs() {
      let tabsArr = [
        { tab_name: 'Dashboard', path: '/hr/dashboard', isShown: false },
        {
          tab_name: 'Pengajuan',
          path: '',
          sub_tabs: [
            {
              sub_title: 'Pengajuan Cuti',
              path: '/hr/leave'
            },
            {
              sub_title: 'Pengajuan Izin',
              path: '/hr/permission'
            },
            {
              sub_title: 'Aktivitas',
              path: '/hr/activity'
            }
          ],
          isShown: false
        },
        {
          tab_name: 'Master',
          path: '',
          isShown: false,
          sub_tabs: [
            {
              sub_title: 'Hirarki',
              path: '',
              sub_tabs: [
                {
                  sub_title: 'Approval',
                  path: '/hr/ms/approval_hierarchy'
                },
                {
                  sub_title: 'Jabatan',
                  path: '/hr/ms/position_hierarchy'
                },
                {
                  sub_title: 'Wewenang',
                  path: '/hr/ms/authority'
                }
              ]
            },
            {
              sub_title: 'Kategori Cuti',
              path: '/hr/ms/leave_category'
            },
            {
              sub_title: 'Kategori Izin',
              path: '/hr/ms/permission_category'
            },
            {
              sub_title: 'Jabatan',
              path: '/hr/ms/position'
            },
            {
              sub_title: 'Department',
              path: '/hr/ms/department'
            },
            {
              sub_title: 'Hari Libur Nasional',
              path: '/hr/ms/holidaysetting'
            },
            {
              sub_title: 'Master Customer',
              path: '/hr/ms/activity_customer'
            }
          ]
        },
        { tab_name: 'Karyawan', path: '/hr/employee', isShown: false },
        {
          tab_name: 'Record',
          path: '',
          sub_tabs: [
            {
              sub_title: 'Log Mesin',
              path: '/hr/machinelog'
            }
          ],
          isShown: false
        }
      ]

      tabsArr.splice(2, 0, {
        tab_name: 'Presensi',
        path: '/hrs/presence_report',
        isShown: false
      })
      // show access to wim
      if (this.getUserProfile.employee.company.plant_id != 6) {
        if (
          this.getUserProfile.employee.company.plant_id == 5 &&
          this.getUserProfile.employee.company.plant_id == 14
        ) {
          tabsArr[3].sub_tabs.splice(1)
          tabsArr[3].sub_tabs[0].sub_tabs.splice(1)
        } else {
          tabsArr.splice(3, 1)
        }
      }
      if (
        this.getUserProfile.level.find(({ id }) => id === '42') !== undefined
      ) {
        tabsArr.splice(3, 1)
        setTimeout(() => {
          tabsArr.splice(4, 1)
        }, 200)
      }
      // show access only to administrator
      // if (this.getUserProfile.employee.company.plant_id == 6) {
      //   const level = this.getUserProfile.level.find(({ id }) => id === '1')
      //   // const level = this.getUserProfile.level.find(
      //   //   ({ id }) => id === '1' || id === '39'
      //   // )
      //   if (level === undefined) {
      //     // tabsArr[3].sub_tabs.splice(1)
      //     tabsArr[3].sub_tabs[0].sub_tabs.splice(0, 1)
      //   }
      // } else {
      //   tabsArr.splice(3, 1)
      // }
      this.setTabs(tabsArr)
    },
    getLocation() {
      if (navigator.geolocation) {
        this.isLocationSupported = true
        navigator.geolocation.getCurrentPosition(this.showPosition)
      } else {
        this.isLocationSupported = false
      }
    },
    showPosition(position) {
      this.form.attend_loc_lt = position.coords.latitude
      this.form.attend_loc_lng = position.coords.longitude
    },
    uploadFoto(event) {
      if (event !== null) {
        this.loading = true
        const data = new FormData()
        data.append('file', event)
        data.append('module', 'photo.attendance')
        axios
          .post(`${this.upload}esanqua/hris`, data)
          .then((res) => {
            if (res.data.status === true) {
              this.showMsgDialog('success', res.data.message, false)
              this.form.photo = res.data.data.name
            } else {
              this.showMsgDialog('warning', res.data.message, false)
            }
            this.loading = false
          })
          .catch((err) => {
            this.loading = false
            this.showMsgDialog(
              'error',
              err
                ? 'Something went wrong, Please contact an admin!'
                : 'Something went wrong, Please contact an admin!',
              false
            )
          })
      } else {
        this.form.photo = null
      }
    },
    close() {
      this.rules = {
        attendFromRules: [],
        attendFromCompanyRules: [],
        descriptionRules: [],
        photoRules: []
      }
      this.dialog = false
      this.form = {
        device_code: null,
        device_type: 3,
        attend_from: null,
        attend_from_company: null,
        attend_loc_lt: null,
        attend_loc_lng: null,
        attend_description: null,
        attend_from_ip: null,
        attend_from_ssid: null,
        hashed_data: null,
        photo: null,
        date: null
      }
      this.getLocation()
      this.getPublicIp()
    },
    async openAttendanceForm(str) {
      this.attendanceTitle = str
      await this.dropdownPlant()
      setTimeout(() => {
        this.dialog = true
      }, 300)
    },
    updateTime() {
      var cd = new Date()
      this.time =
        this.zeroPadding(cd.getHours(), 2) +
        ':' +
        this.zeroPadding(cd.getMinutes(), 2) +
        ':' +
        this.zeroPadding(cd.getSeconds(), 2)
      this.days = `${this.week[cd.getDay()]}`
      this.date = `${this.zeroPadding(cd.getDate(), 2)}-${this.zeroPadding(
        cd.getMonth() + 1,
        2
      )}-${this.zeroPadding(cd.getFullYear(), 4)}`
    },
    zeroPadding(num, digit) {
      var zero = ''
      for (var i = 0; i < digit; i++) {
        zero += '0'
      }
      return (zero + num).slice(-digit)
    },
    async getPublicIp() {
      // if (location.protocol === 'https:') {
      const data = await $.getJSON(
        'https://api.ipify.org?format=json',
        function (data) {
          return data
        }
      )

      this.publicIp = data.ip
      this.form.attend_from_ip = data.ip
    },

    submit() {
      this.rules.attendFromRules = [(v) => !!v || 'Attend from is required']

      if (this.form.attend_from === 1) {
        this.rules.attendFromCompanyRules = [
          (v) => !!v || 'Company is required'
        ]
      }

      if (this.form.attend_from > 1) {
        this.rules.descriptionRules = [(v) => !!v || 'Description is required']
      }
      if (this.form.photo === null) {
        this.rules.photoRules = [(v) => !!v || 'Photo is required']
      }
      const self = this

      setTimeout(function () {
        if (self.$refs.entryForm.validate()) {
          self.createNewForm()
        }
      })
      // navigator.permissions
      //   .query({ name: 'geolocation' })
      //   .then(PermissionStatus => {
      //     console.log(PermissionStatus.state)
      //     if (PermissionStatus.state == 'granted') {
      //       // put here
      //     } else {
      //       this.showMsgDialog('warning', 'Turn on your location access', false)
      //       //denied
      //     }
      //   })
    },
    createNewForm() {
      const form = {
        device_code:
          this.form.device_code !== null ? this.form.device_code : '',
        device_type: this.form.device_type,
        attend_from: this.form.attend_from,
        attend_from_company_id:
          this.form.attend_from_company !== null
            ? this.form.attend_from_company.id
            : null,
        attend_from_company_name:
          this.form.attend_from_company !== null
            ? this.form.attend_from_company.name
            : null,
        attend_loc_lt: this.form.attend_loc_lt,
        attend_loc_lng: this.form.attend_loc_lng,
        attend_description: this.form.attend_description,
        attend_from_ssid:
          this.form.attend_from_ssid !== null ? this.form.attend_from_ssid : '',
        attend_from_ip: this.form.attend_from_ip,
        hashed_data:
          this.form.hashed_data !== null ? this.form.hashed_data : '',
        picture: this.form.photo
      }
      this.save(form)
    },
    async save(form) {
      this.loading = true
      await axios
        .post(`${this.attendanceApi}mobile_attendance/log/add`, form)
        .then(async (res) => {
          if (res.data.status_code !== '-99') {
            this.showMsgDialog('success', res.data.status_msg, false)
            this.getAttendanceStatus()
            this.getTodayAttendance()
            this.close()
          } else {
            if (res.data.status_msg === 'Parameter value has problem') {
              this.showMsgDialog(
                'error',
                'Invalid input, Parameter value has problem',
                false
              )
            } else {
              this.showMsgDialog('error', res.data.status_msg, false)
            }
          }
          this.loading = false
        })
        .catch((err) => {
          this.showMsgDialog(
            'error',
            err
              ? 'Something went wrong, Please contact an admin!'
              : 'Something went wrong, Please contact an admin!',
            false
          )
          this.loading = false
        })
    },
    getTodayAttendance() {
      const today = this.date.split('-').reverse().join('-')
      axios
        .get(
          `${this.attendanceApi}get_by_employee?date_from=${today}&date_to=${today}`
        )
        .then((res) => {
          if (res.data.status_code === '00') {
            this.todayAttend = res.data.data
          } else {
            this.todayAttend = []
          }
        })
        .catch((err) => {
          this.todayAttend = null
          console.log(err)
        })
    },
    getAttendanceStatus() {
      axios
        .get(`${this.attendanceApi}get_last_attendance_status`)
        .then((res) => {
          if (res.data.status_code === '00') {
            this.attendanceStatus = res.data.attendance_type
          } else {
            this.attendanceStatus = null
          }
        })
        .catch((err) => {
          this.attendanceStatus = null
          console.log(err)
        })
    },
    getNetworkPlant() {
      this.result = []
      axios
        .get(
          `${this.eSanQua}plantnetwork/list?keyword=&offset=0&limit=1000&plant_id=`
        )
        .then((res) => {
          if (res.data.status_code === '00') {
            this.result = res.data.data
          } else {
            this.result = []
          }
        })
        .catch((err) => {
          this.result = []
          console.log(err)
        })
    },
    openDetailAttendance(data) {
      this.detailAttendance = data
      if (process.env.VUE_APP_BUILD_TYPE === 'development') {
        this.imgSrc = `https://dev-esanqua.sanquawater.co.id/image/ecatalogue/hris/photo/attendance/${data.picture}`
      } else {
        this.imgSrc = `https://e-sanqua.sanquawater.co.id/image/ecatalogue/hris/photo/attendance/${data.picture}`
      }
      setTimeout(() => {
        this.detailPresensi = true
      }, 300)
    },
    closeImg() {
      this.openImageDialog = false
      // this.isImgError = false
    },
    networkDialog() {
      setTimeout(() => {
        this.openNetworkDialog = true
      }, 300)
    },
    closeListNetwork() {
      setTimeout(() => {
        this.openNetworkDialog = false
      }, 300)
    },
    openCamera() {
      this.cameraDialog = true
      setTimeout(() => {
        document.getElementById('starCamera').click()
      }, 100)
    },
    closeCamera() {
      document.getElementById('stopCamera').click()
      setTimeout(() => {
        this.cameraDialog = false
      }, 100)
    },
    takePicture(event) {
      this.cameraDialog = false
      this.uploadFoto(event)
    },
    convertDate2(raw) {
      if (raw !== null) {
        const date = new Date(raw)
        const local = Intl.DateTimeFormat('id', {
          day: 'numeric',
          month: 'long'
        }).format(date)
        return local
      }
    }
  }
}
</script>
<style scoped lang="scss">
#clock {
  font-family: 'Share Tech Mono', monospace;
  // color: #ffffff;
  text-align: center;
  // position: absolute;
  // left: 50%;
  // top: 50%;
  // transform: translate(-50%, -50%);
  color: #525252;
  font-weight: bold;
  text-shadow: 0 0 20px rgba(9, 175, 230, 9), 0 0 20px rgba(10, 175, 230, 0);
  .time {
    letter-spacing: 0.05em;
    font-size: 80px;
    padding: 5px 0;
    margin: 0;
  }
  .date {
    letter-spacing: 0.1em;
    font-size: 24px;
    margin: 0;
  }
}

.note {
  border: 1px solid rgba(0, 0, 0, 0.2);
  max-width: 400px;
  min-height: 100px;
  margin: auto;
  padding: 10px;
  position: relative;
}
.presensi-title {
  font-size: 16px;
  margin: 0;
  padding: 0;
  font-weight: bold;
  color: indigo;
  margin-bottom: 10px;
}
@media (max-width: 576px) {
  #clock {
    .time {
      font-size: 3em;
    }
    .date {
      font-size: 1em;
    }
  }
}
@media (max-width: 320px) {
  .note {
    min-height: 130px;
  }
  .presensi-title {
    font-size: 14px;
  }
}
</style>
